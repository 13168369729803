<template>
  <WeContainer seo-bar="" card="">
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-globe"></i>
        <span>Teslimat Bölgeleri</span>
      </h5>
    </WeCard>

    <WeCard>
      <span class="btn btn-success" v-on:click="showModal"
        ><i class="fas fa-plus"></i> Yeni Teslimat Bölgesi</span
      >
      <hr />
      <!-- Detail Modal -->
      <NewZoneModal v-if="modal" v-on:close="closeModal" />
      <!-- ./Detail Modal -->

      <Accordion
        v-if="!loading && data && data.length"
        v-model="data"
        v-on:update-list="updateList"
        v-on:get-children="getNodeChildren"
        v-on:show-delivery-times="showNodeDeliveryTimes"
        v-on:on-switch="onSwitch"
      />
      <div style="min-height: 250px; overflow: auto" v-else-if="loading">
        <WeLoading />
      </div>
      <WeCard class="alert-info" v-else>
        <i class="fas fa-info-circle"></i> Teslimat Bölgesi bulunmamaktadır. Tüm
        Şehirlerden sipariş verilebilir.
      </WeCard>

      <WeModal
        v-if="nodeDetail && showNodeDetail"
        v-bind:title="nodeDetail.name + ' - Teslimat Saatleri'"
        v-on:close="onCloseNode"
      >
        <div slot="body">
          <WeDeliveryTime
            v-bind:clone-data="true"
            v-model="nodeDetail.delivery_times"
            v-on:on-submit="onSaveDeliveryTime"
            max-height="400"
          />
        </div>
      </WeModal>
    </WeCard>
  </WeContainer>
</template>
<script>
import { mapActions, mapState } from "vuex";
const NewZoneModal = () => import("./views/DetailModal/Index");
const Accordion = () => import("./views/Accordion/Index");

export default {
  name: "Detail",
  components: {
    NewZoneModal,
    Accordion,
  },
  data() {
    return {
      loading: false,
      modal: false,
      showNodeDetail: false,
      data: [],
      nodeDetail: null,
      columns: [
        { name: "country", th: "Ülke", type: "string" },
        { name: "city", th: "Şehir", type: "string" },
        { name: "district", th: "İlçe", type: "string" },
        { name: "neighborhood", th: "Mahalle", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean" },
      ],
      filter: {
        country: null,
        city: null,
        district: null,
        neighborhood: null,
      },
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
    };
  },
  methods: {
    ...mapActions("deliveryZone", [
      "getList",
      "isActive",
      "getChildren",
      "getDeliveryTimes",
      "saveDeliveryTimes",
    ]),
    showModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      if (this.delivery.changed) {
        this.updateList();
      }
    },
    updateList(data = null) {
      this.loading = true;
      this.getList({
        filter: data,
        onSuccess: (result) => {
          this.data = this.setLoadingToItem(result.data.data);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
    setLoadingToItem(items) {
      return items.map((item) => {
        if (item.hasOwnProperty("children")) {
          item.loading = false;
        }
        return item;
      });
    },
    getNodeChildren(data) {
      data.node.loading = true;

      this.getChildren({
        node: data.node,
        parents: data.parents,
        onSuccess: (result) => {
          data.node.children = this.setLoadingToItem(result.data.items);
          data.node.show = true;
        },
        onFinish: () => {
          data.node.loading = false;
        },
      });
    },
    showNodeDeliveryTimes(data) {
      this.nodeDetail = data.node;
      this.showNodeDetail = true;
    },
    onCloseNode() {
      this.nodeDetail = null;
      this.showNodeDetail = false;
    },
    onSwitch(data) {
      this.isActive({
        form: data,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            changeNodeStatus(data.node, data.value);
            this.$toast.success("Durum Güncellendi");
          } else {
            this.$toast.error("Durum Güncellenemedi");
          }
        },
      });

      function changeNodeStatus(node, checked) {
        if (node) {
          node.is_active = checked;

          if (node.children && node.children.length) {
            node.children.forEach((child) => {
              changeNodeStatus(child, checked);
            });
          }
        }
      }
    },
    onSaveDeliveryTime(data) {
      this.nodeDetail.delivery_times = data.list;
      this.saveDeliveryTimes({
        parents: data.parents,
        node: this.nodeDetail,
        onSuccess: (result) => {
          this.$toast.success(
            this.nodeDetail.name + " Teslimat Saati Güncellendi !"
          );
        },
        onFinish: () => {
          data.loading = false;
          this.onCloseNode();
        },
        onError: (err) => {
          this.$toast.error(
            this.nodeDetail.name + " Teslimat Saati Güncellenemedi !"
          );
        },
      });
      setTimeout(() => {
        data.loading = false;
        this.$toast.success(
          this.nodeDetail.name + " teslimat saati güncellendi"
        );
        this.onCloseNode();
      }, 1000);
    },
  },
  computed: {
    ...mapState({
      delivery: (state) => state.deliveryZone,
    }),
  },
  mounted() {
    this.updateList();
  },
};
</script>
